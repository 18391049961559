import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga4';
import { useMutation } from 'react-query';
import './login.css';

// Initialize Google Analytics
ReactGA.initialize('G-WE08Z9V528'); // Replace with your Google Analytics 4 Measurement ID

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const [deviceId, setDeviceId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    let id = localStorage.getItem('deviceId');
    if (!id) {
      id = uuidv4();
      localStorage.setItem('deviceId', id);
    }
    console.log(id);
    setDeviceId(id);

    // Send pageview to Google Analytics
    ReactGA.send({ hitType: "pageview", page: "/login" });
  }, []);

  const loginMutation = useMutation(
    async () => {
      const response = await axios.post('/api/login', {
        email: email.toLowerCase(),
        password: password,
        stay_logged_in: stayLoggedIn,
        deviceId: deviceId
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data.exists || data.success) {
          localStorage.setItem('userName', data.name);
          // Track successful login
          ReactGA.event({
            category: 'User',
            action: 'Login',
            label: 'Successful'
          });
          navigate('/chat');
        } else {
          // Track redirect to registration
          ReactGA.event({
            category: 'User',
            action: 'Redirect to Registration',
            label: 'From Login'
          });
          navigate('/register', { state: { email: email.toLowerCase() } });
        }
      },
      onError: (error) => {
        console.error('Erro ao verificar email:', error);
        // Track login error
        ReactGA.event({
          category: 'User',
          action: 'Login',
          label: 'Error'
        });
      }
    }
  );

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    // Track login attempt
    ReactGA.event({
      category: 'User',
      action: 'Login Attempt'
    });
    loginMutation.mutate();
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="login-title">Bem-vindo</h2>
        {loginMutation.isError && (
          <div className="error-alert">
            Ocorreu um erro. Por favor, tente novamente.
          </div>
        )}
        {loginMutation.isLoading && (
          <div className="loading-alert">
            Carregando...
          </div>
        )}
        <form onSubmit={handleEmailSubmit}>
          <div className="form-group">
            <label htmlFor="email" className="form-label">E-mail</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-input"
            />
            <label htmlFor="password" className="form-label">Senha</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="form-input"
            />
            <div className="form-group">
              <input
                type="checkbox"
                id="stayLoggedIn"
                checked={stayLoggedIn}
                onChange={(e) => {
                  setStayLoggedIn(e.target.checked);
                  // Track "Stay Logged In" checkbox
                  ReactGA.event({
                    category: 'User',
                    action: 'Toggle Stay Logged In',
                    label: e.target.checked ? 'Checked' : 'Unchecked'
                  });
                }}
              />
              <label htmlFor="stayLoggedIn" className="form-label">Permanecer logado</label>
            </div>
          </div>
          <button type="submit" className="submit-button" disabled={loginMutation.isLoading}>
            Continuar
          </button>
        </form>
        <p className="register-link">
          Ainda não está registrado? <a href="/register" className="register-link-text" onClick={() => {
            // Track click on registration link
            ReactGA.event({
              category: 'User',
              action: 'Click Registration Link',
              label: 'From Login Page'
            });
          }}>Registre-se aqui</a>.
        </p>
      </div>
    </div>
  );
};

export default Login;