import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Home from './pages/Home/Home';
// import Home from './pages/Home/Home-o1';
import Login from './pages/Login/Login';
import Register from './pages/Login/Register';
import ChatInterface from './pages/Chat/ChatInterface';
import { initGA, logPageView } from './analytics';
import './styles/main.css';
import './styles/chat.css';

const queryClient = new QueryClient();

function AppRoutes() {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/chat" element={<ChatInterface />} />
    </Routes>
  );
}

function App() {
  useEffect(() => {
    initGA();
  }, []);

  return (
      <Router>
        <QueryClientProvider client={queryClient}>
          <div className="App">
            <AppRoutes />
          </div>
        </QueryClientProvider>
      </Router>
  );
}

export default App;