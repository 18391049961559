import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const SidebarMobile = () => (
  <div className="text-xs md:text-sm text-tertiary text-center mt-1 mb-1">
    <p>Um experimento da WE Heart Impact.</p>
  </div>
);

const ChatInput = ({ input, setInput, sendMessage, waitingForResponse, showFeedbackForm }) => {
  return (
    <div className="w-full px-4 md:w-4/5 mx-auto mb-4 md:mb-6">
      <div className="bg-feature-bg rounded-full shadow-md mb-2">
        <div className="flex items-center p-2">
          <textarea
            rows="1"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Digite sua mensagem..."
            onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && sendMessage()}
            disabled={waitingForResponse || showFeedbackForm}
            className="flex-grow resize-none bg-transparent text-text placeholder-tertiary focus:outline-none py-2 px-4 text-sm md:text-base"
          />
          <button
            className="send-button p-2 rounded-full bg-primary text-text hover:bg-secondary transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={sendMessage}
            disabled={waitingForResponse || showFeedbackForm}
          >
            <FontAwesomeIcon icon={faPaperPlane} className="w-4 h-4 md:w-5 md:h-5" />
          </button>
        </div>
      </div>
      {/* SidebarMobile positioned between the chat input and the disclaimer */}
      <div className="md:hidden">
        <SidebarMobile />
      </div>
      {/* Disclaimer with the same styling as SidebarMobile */}
      <div className="text-xs md:text-sm text-tertiary text-center mt-1 mb-1">
        Abilio.ai pode cometer erros. Verifique informações importantes.
      </div>
    </div>
  );
};

export default ChatInput;
