import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ selectedMode, showModeDropdown, setShowModeDropdown, handleModeChange, handleLogout }) => {
  return (
    <div className="header-container">
      <div className="flex justify-between items-center bg-background p-4 rounded-md shadow-md">
        {/* Model Selector */}
        <div className="relative">
          <button
            aria-label={`Model selector, current model is ${selectedMode}`}
            type="button"
            onClick={() => setShowModeDropdown((prev) => !prev)}
            className="flex items-center gap-2 rounded-lg py-2 px-4 text-base font-semibold bg-primary text-text hover:bg-secondary"
          >
            <div>{selectedMode}</div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 text-tertiary"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29289 9.29289C5.68342 8.90237 6.31658 8.90237 6.70711 9.29289L12 14.5858L17.2929 9.29289C17.6834 8.90237 18.3166 8.90237 18.7071 9.29289C19.0976 9.68342 19.0976 10.3166 18.7071 10.7071L12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071L5.29289 10.7071C4.90237 10.3166 4.90237 9.68342 5.29289 9.29289Z"
                fill="currentColor"
              />
            </svg>
          </button>
          {showModeDropdown && (
            <div className="absolute mt-2 bg-feature-bg border border-secondary rounded-md shadow-lg z-10">
              <div
                className="py-2 px-4 cursor-pointer text-text hover:bg-primary hover:text-feature-bg"
                onClick={() => handleModeChange('Abilio Gerente')}
              >
                Abilio Gerente
              </div>
              <div
                className="py-2 px-4 cursor-pointer text-text hover:bg-primary hover:text-feature-bg"
                onClick={() => handleModeChange('Abilio Contador')}
              >
                Abilio Contador
              </div>
            </div>
          )}
        </div>
        {/* Logout Button */}
        <Link to="/" onClick={handleLogout} className="text-text text-base font-semibold hover:text-secondary ml-4">
          Sair
        </Link>
      </div>
    </div>
  );
};

export default Header;
