import React, { useState, useEffect, useRef,useCallback } from 'react';
import FeedbackForm from './FeedbackForm';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/index.css';
import Sidebar from '../../components/Sidebar';
import Header from './Header';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import '../../styles/main.css';

function ChatInterface() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [feedbackIcons, setFeedbackIcons] = useState({});
  const messagesEndRef = useRef(null);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState('');
  const [feedbackDetails, setFeedbackDetails] = useState([]);
  const currentMessageId = useRef(null);
  const navigate = useNavigate();
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [selectedMode, setSelectedMode] = useState('Abilio Gerente');
  const [showModeDropdown, setShowModeDropdown] = useState(false);
  const isThrottled = useRef(false);
  const handleModeChange = (mode) => {
    setSelectedMode(mode);
    setShowModeDropdown(false);
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: `Modo alterado para ${mode}`, isUser: false },
    ]);
  };

  const handleFeedback = (type, messageId) => {
    console.log(`Feedback type: ${type} for message ID: ${messageId}`);
    currentMessageId.current = messageId;
    setFeedbackIcons((prev) => ({
      ...prev,
      [messageId]: prev[messageId] === type ? null : type,
    }));

    setFeedbackType(type);
    if (type === 'positive') {
      submitFeedback();
    } else {
      setShowFeedbackForm(true);
    }
  };

  const submitFeedback = async () => {
    console.log('Feedback submitted:', feedbackType, feedbackDetails, feedback);

    const feedbackMessage = messages[currentMessageId.current];

    try {
      const response = await axios.post('/api/feedback', {
        messageId: currentMessageId.current,
        feedbackType: feedbackType,
        details: [feedbackDetails, feedback],
        context: messages,
        message: feedbackMessage.text,
        isUserMessage: feedbackMessage.isUser,
      });
      console.log(response.data);

      // Reset the feedback form fields
      setFeedbackType('');
      setFeedbackDetails([]);
      setFeedback('');
      setShowFeedbackForm(false);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  const handleFeedbackSubmit = async (event) => {
    event.preventDefault();
    await submitFeedback();
  };

  const handleFeedbackChange = (event) => {
    const { value, checked } = event.target;
    setFeedbackDetails((prevDetails) =>
      checked
        ? [...prevDetails, value]
        : prevDetails.filter((detail) => detail !== value)
    );
  };

  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      await axios.post('/api/logout');
      localStorage.removeItem('userName');
      navigate('/');
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
      navigate(error.response.data.redirect);
    }
  };

  useEffect(() => {
    const fetchInitialMessage = async () => {
      try {
        setLoading(true);
        setWaitingForResponse(true);
        const response = await axios.post('/api/chat', { message: '' });
        setMessages([{ text: response.data.response, isUser: false }]);
      } catch (error) {
        console.error('Error fetching initial message:', error);
        navigate(error.response.data.redirect);
        // } else {
        //   setMessages((prev) => [
        //     ...prev,
        //     {
        //       text: 'Por favor, acesse através da página inicial. https://abilio.ai',
        //       isUser: false,
        //     },
          // ]);
        } finally {
        setLoading(false);
        setWaitingForResponse(false);
      }
    };

    fetchInitialMessage();

    const handleUnload = () => {
      if (navigator.sendBeacon) {
        navigator.sendBeacon('/api/clear');
      } else {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/api/clear', false);
        xhr.send();
      }
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [navigate]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const sendMessage = useCallback(async () => {
    // Check if throttled
    if (isThrottled.current) {
      return; // If currently throttled, exit early
    }

    if (input.trim() === '') return;

    setMessages([...messages, { text: input, isUser: true }]);
    setInput('');
    setLoading(true);

    // Format the selected model to lowercase and replace spaces with hyphens
    const formattedMode = selectedMode.toLowerCase().replace(/\s+/g, '-');

    try {
      setWaitingForResponse(true);
      const response = await axios.post('/api/chat', {
        message: input,
        mode: formattedMode // Include the formatted model in the request payload
      });
      console.log(response.data.response);
      setMessages(prev => [...prev, { text: response.data.response, isUser: false }]);
    } catch (error) {
      console.error('Error sending message:', error);
      navigate(error.response.data.redirect);
      setMessages(prev => [...prev, {
        text: 'Por favor, acesse através da página inicial. https://abilio.ai',
        isUser: false
      }]);
    } finally {
      setWaitingForResponse(false);
      setLoading(false);
      // Set throttle to true and reset after 1000ms (1 second)
      isThrottled.current = true;
      setTimeout(() => {
        isThrottled.current = false;
      }, 1000);
    }
  }, [input, messages, navigate, selectedMode]);  // Add selectedModel as a dependency


  const messageRef = useRef(null);

  useEffect(() => {
    messageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <>
      <div className="chat-layout">
        <Sidebar />
        <div className="bg-background chat-area">
          <Header
            selectedMode={selectedMode}
            showModeDropdown={showModeDropdown}
            setShowModeDropdown={setShowModeDropdown}
            handleModeChange={handleModeChange}
            handleLogout={handleLogout}
          />

          <ChatMessages
            messages={messages}
            feedbackIcons={feedbackIcons}
            handleFeedback={handleFeedback}
            loading={loading}
            messageRef={messageRef}
            selectedMode={selectedMode}
          />
          <ChatInput
            input={input}
            setInput={setInput}
            sendMessage={sendMessage}
            waitingForResponse={waitingForResponse}
            showFeedbackForm={showFeedbackForm}
          />
          <div ref={messagesEndRef} />
        </div>
      </div>
      {showFeedbackForm && (
        <div className="feedback-overlay">
          <FeedbackForm
            onClose={() => setShowFeedbackForm(false)}
            onSubmit={handleFeedbackSubmit}
            onChange={handleFeedbackChange}
            feedback={feedback}
            setFeedback={setFeedback}
          />
        </div>
      )}
    </>
  );
}

export default ChatInterface;
