import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faChartLine, faStore, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga4';
import './home.css';
import '../../styles/main.css';

// Initialize Google Analytics
ReactGA.initialize('G-WE08Z9V528'); // Replace with your Google Analytics tracking ID

const Footer = () => (
  <footer className="bg-tertiary text-text py-6">
    <div className="container mx-auto px-4 text-center">
      <p>&copy; 2024 Abilio AI. Todos os direitos reservados.</p>
    </div>
  </footer>
);



const Home = () => {
  useEffect(() => {    // Function to get device type
    const getDeviceType = () => {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        return "mobile";
      }
      return "desktop";
    };

    // Function to get user's location
    const getUserLocation = () => {
      return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
            },
            (error) => {
              console.error("Error getting location:", error);
              resolve(null);
            }
          );
        } else {
          console.log("Geolocation is not supported by this browser.");
          resolve(null);
        }
      });
    };

    // Collect and send data to Google Analytics
    const sendAnalytics = async () => {
      const deviceType = getDeviceType();
      const location = await getUserLocation();

      ReactGA.send({
        hitType: "pageview",
        dimension1: deviceType,
        dimension2: location ? `${location.latitude},${location.longitude}` : "Not available",
      });
    };

    sendAnalytics();
  }, []);

  const features = [
    { icon: faRobot, title: 'Assistente IA 24/7', description: 'Suporte instantâneo e personalizado para todas as dúvidas operacionais dos franqueados.' },
    { icon: faChartLine, title: 'Análise de Desempenho', description: 'Insights detalhados e recomendações para melhorar o desempenho da franquia.' },
    { icon: faStore, title: 'Otimização de Operações', description: 'Dicas e estratégias para aprimorar a eficiência operacional e reduzir custos.' },
    { icon: faGraduationCap, title: 'Treinamento Contínuo', description: 'Acesso a conhecimento atualizado e melhores práticas do setor de franquias.' },
  ];

  const handleStartNowClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Start Now Button',
    });
  };

  return (
    <div className="bg-background text-text font-main min-h-screen flex flex-col">
      <main className="flex-grow">
        <section className="bg-gradient-to-br from-primary via-white to-secondary py-12 sm:py-20">
          <div className="container mx-auto px-4 text-center">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-text">
              Abilio, o sócio especialista do franqueado Brasileiro
            </h1>
            <p className="text-base sm:text-lg md:text-xl mb-8 text-text">
              Assistente de Inteligência Artificial para impulsionar o sucesso da sua franquia.
            </p>
            <Link
              to="/login"
              className="bg-secondary text-white font-semibold py-2 px-6 sm:py-3 sm:px-8 md:py-4 md:px-10 text-sm sm:text-base md:text-lg rounded-full hover:bg-opacity-90 transition duration-300 inline-block shadow-md hover:shadow-lg"
              onClick={handleStartNowClick}
            >
              Comece Agora!
            </Link>
          </div>
        </section>
        <section className="py-8 sm:py-12 md:py-16 bg-background">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 md:gap-8">
              {features.map((feature, index) => (
                <div key={index} className="bg-feature-bg rounded-lg shadow-md hover:shadow-lg p-4 sm:p-6 transition duration-300 flex flex-col text-center h-full">
                  <div className="flex-grow">
                    <FontAwesomeIcon icon={feature.icon} className="text-3xl sm:text-4xl md:text-5xl text-feature-icon mb-3 sm:mb-4" />
                    <h3 className="text-base sm:text-lg md:text-xl font-semibold mb-2 text-text">{feature.title}</h3>
                    <p className="text-sm sm:text-base text-text mb-4">{feature.description}</p>
                  </div>
                  <div className="mt-auto">
                    {index > 0 && (
                      <span className="inline-block text-s font-semibold bg-yellow-300 text-black px-5 py-1 rounded-full">
                        EM BREVE
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Home;