import React from 'react';


function FeedbackForm({ onClose, onSubmit, onChange, feedback, setFeedback }) {
  return (
    <div className="feedback-overlay">
      <div className="feedback-popup">
        <h2>Sugestão de Melhora</h2>
        <form onSubmit={onSubmit}>
          <label>
            <input type="checkbox" value=" Impreciso" onChange={onChange} />
            Informação imprecisa
          </label>
          <label>
            <input type="checkbox" value=" Incompleto" onChange={onChange} />
            Resposta incompleta
          </label>
          <label>
            <input type="checkbox" value=" Irrelevante" onChange={onChange} />
            Não relevante para a pergunta
          </label>
          <label>
            <input type="checkbox" value=" Confuso" onChange={onChange} />
            Difícil de entender
          </label>
          <input 
            type="text" 
            placeholder="Outro" 
            value={feedback} 
            onChange={(e) => setFeedback(e.target.value)} // Use the setFeedback passed as prop
          />
          <button type="submit">Mandar Sugestão</button>
          <button type="button" onClick={onClose}>Fechar</button> {/* Updated to type="button" to prevent form submission */}
        </form>
      </div>
    </div>
  );
}

export default FeedbackForm;
