import React from 'react';

const LoadingIndicator = () => (
  <div className="chat-message loading">
    <div className="message-bubble">
      <div className="loading-bubble">
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
      </div>
    </div>
  </div>
);

export default LoadingIndicator;
