import React from "react";

const Sidebar = () => {
  return (
    <div className="sidebar">
        <div className="sidebar-text">
        <img className="sidebar-icon_br" width="48" height="48" src="https://img.icons8.com/color/48/brazil-circular.png" alt="brazil-circular"/>
          <div className="sidebar-text_paragraphs">
            <div>
            Estamos construindo <p>Abilio, o primeiro AI co-founder para franquias do mundo,</p> 
            e vamos fazer isso para o mercado Brasileiro.
            </div>
            <br/>
            <div>
            A jornada é super desafiadora e contamos com o seu feedback nessa evolução.  
            </div>
            <br/>
            <div>
            Toda <p className="week">Segunda-feira,</p> lançamos uma nova versão.
            </div> 
          </div>
        </div>
        {/* Removed the mapping of mockPreviousChats */}
        <div className="sidebar-footer">
          <p>Versão 1.0.7</p>
          <p>Ultimo Update 16.09.2024</p>
          <p>Um experimento da WE Heart Impact</p>
        </div>
      </div>
  );
}

export default Sidebar;