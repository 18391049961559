import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import LoadingIndicator from './LoadingIndicator';

const ChatMessages = ({
  messages,
  feedbackIcons,
  handleFeedback,
  loading,
  messageRef,
  selectedMode // Accept selectedMode as a prop
}) => {
  // Function to get initials from the selected mode
  const getInitials = (name) => {
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase();
  };

  useEffect(() => {
    messageRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages, messageRef]);

  return (
    <div className="flex-grow overflow-y-auto px-4 md:px-[15%] bg-background text-text">
      <div className="flex flex-col space-y-4 md:space-y-6 py-4 md:py-6">
        {messages.map((message, index) => (
          <React.Fragment key={index}>
            <div className={`flex flex-col ${message.isUser ? 'items-end' : 'items-start'}`}>
              {message.isUser ? (
                <div className="bg-feature-bg rounded-lg p-2 md:p-3 max-w-[90%] md:max-w-[80%] shadow-md">
                  <ReactMarkdown className="prose prose-sm md:prose-base max-w-none">
                    {message.text}
                  </ReactMarkdown>
                </div>
              ) : (
                <div className="flex flex-col items-start max-w-full w-full mt-2 md:mt-4">
                    <div className="flex items-start space-x-2 md:space-x-3 mb-1 md:mb-2">
                    {/* Rounded symbol with current mode initials */}
                    <div className="w-8 h-8 md:w-10 md:h-10 bg-[#EFEDE7] rounded-full flex items-center justify-center flex-shrink-0">
                      <span className="text-black text-sm md:text-base font-semibold">
                        {getInitials(selectedMode)}
                      </span>
                    </div>
                    <div className="flex-grow">
                      <ReactMarkdown className="prose prose-sm md:prose-base max-w-none leading-relaxed">
                        {message.text}
                      </ReactMarkdown>
                    </div>
                  </div>
                  <div className="flex justify-end w-full space-x-1 md:space-x-2 mt-1">
                    <button
                      className={`p-1 md:p-2 rounded-full transition-colors duration-200 ${
                        feedbackIcons[index] === 'positive'
                          ? 'text-green-500'
                          : 'text-tertiary hover:text-green-300'
                      }`}
                      onClick={() => handleFeedback('positive', index)}
                    >
                      <svg className="w-5 h-5 md:w-6 md:h-6" viewBox="0 0 24 24" fill={feedbackIcons[index] === 'positive' ? 'currentColor' : 'none'} stroke="currentColor" strokeWidth="2">
                        <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
                      </svg>
                    </button>
                    <button
                      className={`p-1 md:p-2 rounded-full transition-colors duration-200 ${
                        feedbackIcons[index] === 'negative'
                          ? 'text-red-500'
                          : 'text-tertiary hover:text-red-300'
                      }`}
                      onClick={() => handleFeedback('negative', index)}
                    >
                      <svg className="w-5 h-5 md:w-6 md:h-6" viewBox="0 0 24 24" fill={feedbackIcons[index] === 'negative' ? 'currentColor' : 'none'} stroke="currentColor" strokeWidth="2">
                        <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17" />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        ))}
        {loading && <LoadingIndicator />}
        <div ref={messageRef} />
      </div>
    </div>
  );
};

export default ChatMessages;
