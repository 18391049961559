import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useMutation } from 'react-query';
import './login.css';

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    franchiseName: '',
    units: '',
    industry: [],
    industryOther: '',
    painPoint: [],
    painPointOther: ''
  });
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const industries = ['Alimentação', 'Educação', 'Saúde', 'Varejo', 'Serviços'];
  const painPoints = ['Gestão de Equipe', 'Finanças', 'Marketing', 'Operações', 'Tecnologia'];

  useEffect(() => {
    if (location.state && location.state.email) {
      setFormData(prevState => ({ ...prevState, email: location.state.email }));
    }
  }, [location.state]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: name === 'email' ? value.toLowerCase() : value }));
  };

  useEffect(() => {
    document.body.style.overflow = 'auto';
    return () => {
      document.body.style.overflow = 'hidden';
    };
  }, []);

  const handleCheckboxChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => {
      const currentSelection = prevState[name];
      if (currentSelection.includes(value)) {
        return { ...prevState, [name]: currentSelection.filter(item => item !== value) };
      } else {
        return { ...prevState, [name]: [...currentSelection, value] };
      }
    });
  };

  const mutation = useMutation(
    (newUser) => axios.post('/api/register', newUser),
    {
      onSuccess: (data) => {
        if (data.data.success) {
          localStorage.setItem('userName', data.data.name);
          navigate('/chat');
        } else {
          setError(data.data.message || 'Falha no registro. Por favor, tente novamente.');
        }
      },
      onError: (error) => {
        console.error('Erro ao registrar usuário:', error);
        if (error.response && error.response.data && error.response.data.message) {
          setError(error.response.data.message);
        } else {
          setError('Ocorreu um erro. Por favor, tente novamente.');
        }
      }
    }
  );

  const handleRegistration = (e) => {
    e.preventDefault();
    mutation.mutate(formData);
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="login-title">Registro</h2>
        {mutation.isError && (
          <div className="error-alert">
            {error}
          </div>
        )}
        {mutation.isLoading && (
          <div className="loading-alert">
            Carregando...
          </div>
        )}
        <form onSubmit={handleRegistration}>
          <div className="form-group">
            <label htmlFor="name" className="form-label">Nome</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="register-email" className="form-label">E-mail</label>
            <input
              type="email"
              id="register-email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="register-password" className="form-label">Senha</label>
            <input
              type="password" 
              id="register-password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="franchiseName" className="form-label">Nome da Franquia</label>
            <input
              type="text"
              id="franchiseName"
              name="franchiseName"
              value={formData.franchiseName}
              onChange={handleInputChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="units" className="form-label">Quantas unidades você possui?</label>
            <input
              type="number"
              id="units"
              name="units"
              value={formData.units}
              onChange={handleInputChange}
              required
              min="0"
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label className="form-label">Qual é o setor/indústria?</label>
            {industries.map((industry, index) => (
              <div key={index} className="checkbox-group">
                <input
                  type="checkbox"
                  id={`industry-${index}`}
                  name="industry"
                  value={industry}
                  checked={formData.industry.includes(industry)}
                  onChange={handleCheckboxChange}
                  className="checkbox-input"
                />
                <label htmlFor={`industry-${index}`} className="checkbox-label">{industry}</label>
              </div>
            ))}
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="industry-other"
                name="industry"
                value="Outro"
                checked={formData.industry.includes('Outro')}
                onChange={handleCheckboxChange}
                className="checkbox-input"
              />
              <label htmlFor="industry-other" className="checkbox-label">Outro</label>
            </div>
            {formData.industry.includes('Outro') && (
              <input
                type="text"
                name="industryOther"
                value={formData.industryOther}
                onChange={handleInputChange}
                placeholder="Especifique o setor"
                className="form-input"
              />
            )}
          </div>
          <div className="form-group">
            <label className="form-label">Quais áreas do seu negócio são mais desafiadoras e que você gostaria de ter maior suporte?</label>
            {painPoints.map((point, index) => (
              <div key={index} className="checkbox-group">
                <input
                  type="checkbox"
                  id={`painPoint-${index}`}
                  name="painPoint"
                  value={point}
                  checked={formData.painPoint.includes(point)}
                  onChange={handleCheckboxChange}
                  className="checkbox-input"
                />
                <label htmlFor={`painPoint-${index}`} className="checkbox-label">{point}</label>
              </div>
            ))}
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="painPoint-other"
                name="painPoint"
                value="Outro"
                checked={formData.painPoint.includes('Outro')}
                onChange={handleCheckboxChange}
                className="checkbox-input"
              />
              <label htmlFor="painPoint-other" className="checkbox-label">Outro</label>
            </div>
            {formData.painPoint.includes('Outro') && (
              <input
                type="text"
                name="painPointOther"
                value={formData.painPointOther}
                onChange={handleInputChange}
                placeholder="Especifique o ponto de dor"
                className="form-input"
              />
            )}
          </div>
          <button type="submit" className="submit-button" disabled={mutation.isLoading}>
            Registrar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;


/* import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './login.css';

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    franchiseName: '',
    units: '',
    industry: [],
    industryOther: '',
    painPoint: [],
    painPointOther: ''
  });
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const industries = ['Alimentação', 'Educação', 'Saúde', 'Varejo', 'Serviços'];
  const painPoints = ['Gestão de Equipe', 'Finanças', 'Marketing', 'Operações', 'Tecnologia'];

  useEffect(() => {
    if (location.state && location.state.email) {
      setFormData(prevState => ({ ...prevState, email: location.state.email }));
    }
  }, [location.state]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: name === 'email' ? value.toLowerCase() : value }));
  };
  useEffect(() => {
    document.body.style.overflow = 'auto';
    return () => {
      document.body.style.overflow = 'hidden';
    };
  }, []);

  const handleCheckboxChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => {
      const currentSelection = prevState[name];
      if (currentSelection.includes(value)) {
        return { ...prevState, [name]: currentSelection.filter(item => item !== value) };
      } else {
        return { ...prevState, [name]: [...currentSelection, value] };
      }
    });
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/register', formData);
      if (response.data.success) {
        localStorage.setItem('userName', response.data.name);
        navigate('/chat');
      } else {
        setError(response.data.message || 'Falha no registro. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao registrar usuário:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Ocorreu um erro. Por favor, tente novamente.');
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="login-title">Registro</h2>
        {error && (
          <div className="error-alert">
            {error}
          </div>
        )}
        <form onSubmit={handleRegistration}>
          <div className="form-group">
            <label htmlFor="name" className="form-label">Nome</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="register-email" className="form-label">E-mail</label>
            <input
              type="email"
              id="register-email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="register-password" className="form-label">Senha</label>
            <input
              type="password" 
              id="register-password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="franchiseName" className="form-label">Nome da Franquia</label>
            <input
              type="text"
              id="franchiseName"
              name="franchiseName"
              value={formData.franchiseName}
              onChange={handleInputChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="units" className="form-label">Quantas unidades você possui?</label>
            <input
              type="number"
              id="units"
              name="units"
              value={formData.units}
              onChange={handleInputChange}
              required
              min="0"
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label className="form-label">Qual é o setor/indústria?</label>
            {industries.map((industry, index) => (
              <div key={index} className="checkbox-group">
                <input
                  type="checkbox"
                  id={`industry-${index}`}
                  name="industry"
                  value={industry}
                  checked={formData.industry.includes(industry)}
                  onChange={handleCheckboxChange}
                  className="checkbox-input"
                />
                <label htmlFor={`industry-${index}`} className="checkbox-label">{industry}</label>
              </div>
            ))}
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="industry-other"
                name="industry"
                value="Outro"
                checked={formData.industry.includes('Outro')}
                onChange={handleCheckboxChange}
                className="checkbox-input"
              />
              <label htmlFor="industry-other" className="checkbox-label">Outro</label>
            </div>
            {formData.industry.includes('Outro') && (
              <input
                type="text"
                name="industryOther"
                value={formData.industryOther}
                onChange={handleInputChange}
                placeholder="Especifique o setor"
                className="form-input"
              />
            )}
          </div>
          <div className="form-group">
            <label className="form-label">Quais áreas do seu negócio são mais desafiadoras e que você gostaria de ter maior suporte?</label>
            {painPoints.map((point, index) => (
              <div key={index} className="checkbox-group">
                <input
                  type="checkbox"
                  id={`painPoint-${index}`}
                  name="painPoint"
                  value={point}
                  checked={formData.painPoint.includes(point)}
                  onChange={handleCheckboxChange}
                  className="checkbox-input"
                />
                <label htmlFor={`painPoint-${index}`} className="checkbox-label">{point}</label>
              </div>
            ))}
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="painPoint-other"
                name="painPoint"
                value="Outro"
                checked={formData.painPoint.includes('Outro')}
                onChange={handleCheckboxChange}
                className="checkbox-input"
              />
              <label htmlFor="painPoint-other" className="checkbox-label">Outro</label>
            </div>
            {formData.painPoint.includes('Outro') && (
              <input
                type="text"
                name="painPointOther"
                value={formData.painPointOther}
                onChange={handleInputChange}
                placeholder="Especifique o ponto de dor"
                className="form-input"
              />
            )}
          </div>
          <button type="submit" className="submit-button">
            Registrar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;

*/